<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex align-top pb-0 justify-space-between flex-wrap-reverse"
    >
      <v-btn-toggle
        v-model="interval"
        mandatory
        dense
        class="elevation-2"
        style="height: max-content"
        @change="onChangeInterval"
      >
        <v-btn
          v-for="intervalObj in intervals"
          :key="`interval-${intervalObj.value}`"
          depressed
          :ripple="false"
          :disabled="arbitraryRangeEnabled"
        >
          {{ intervalObj.text }}
        </v-btn>

        <slot name="append-btn-toggle" />
      </v-btn-toggle>

      <slot name="append-outer-btn-toggle" />
    </v-col>

    <v-col
      cols="12"
    >
      <clip-loader
        v-if="isLoadingRange"
        :loading="true"
        :color="'#8A8D93'"
        size="16px"
        style="text-align: left !important;"
      />

      <v-menu
        v-else
        v-model="isPickingDate"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <transition name="fade">
            <v-text-field
              :key="`date-picker-${arbitraryRangeEnabled}`"
              :value="displayRange"
              :prepend-inner-icon="icons.mdiCalendar"
              readonly
              class="text-base"
              style="animation-duration: 0.1s"
              v-bind="attrs"
              v-on="on"
            >
              <template #prepend>
                <v-checkbox
                  v-if="allowArbitraryRange"
                  v-model="arbitraryRangeEnabled"
                  hide-details
                  :ripple="false"
                  :on-icon="icons.mdiLockOpenVariant"
                  :off-icon="icons.mdiLock"
                  class="ma-0 pa-0"
                />
              </template>

              <template #append-outer>
                <slot name="append-outer" />
              </template>
            </v-text-field>
          </transition>
        </template>

        <v-date-picker
          v-if="!isArbitraryRangeFetch"
          v-model="date"
          locale="ja"
          :day-format="date => new Date(date).getDate()"
          color="primary"
          no-title
          :max="addMonths(new Date(), 1).toISOString().substr(0, 10)"
          @change="onPickDate"
        />
        <v-date-picker
          v-else
          v-model="rangeDates"
          range
          locale="ja"
          :day-format="date => new Date(date).getDate()"
          color="primary"
          no-title
          :max="addMonths(new Date(rangeDates[0]), 1).toISOString().substr(0, 10)"
          @change="onPickRangeDates($event)"
        />
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  computed,
  watch,
  inject,
  toRefs,
} from '@vue/composition-api'
import { compact } from 'lodash'
import { format, addMonths } from 'date-fns'
import { mdiCalendar, mdiLockOpenVariant, mdiLock } from '@mdi/js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import IntervalApi from '@/api/waiter/Interval'
import useQueryString from '@/views/composable/useQueryString'

export default {
  components: {
    ClipLoader,
  },
  props: {
    year: {
      type: Boolean,
      default: true,
    },
    allowArbitraryRange: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { route, addQuery, removeQuery } = useQueryString()
    const currentClubMeta = inject('currentClubMeta', { today: new Date().toISOString().substr(0, 10) })
    const date = ref(route.value.query.date || currentClubMeta.today)
    const startTime = ref()
    const endTime = ref()
    const interval = ref(Number(route.value.query.interval) || 0)
    const endDate = ref(route.value.query.endDate)
    const arbitraryRangeEnabled = ref(props.allowArbitraryRange && !!endDate.value)
    const rangeDates = ref([date.value, (endDate.value || date.value)])
    const isPickingDate = ref(false)
    const isLoadingRange = ref(false)
    const intervals = compact([
      { value: 0, text: '日' },
      { value: 1, text: '週' },
      { value: 2, text: '月' },
      props.year && { value: 3, text: '年' },
    ])

    const displayRange = computed(() => {
      if (interval.value === 0 && !arbitraryRangeEnabled.value) {
        return format(new Date(date.value.replace(/-/g, '/')), 'yyyy/MM/dd')
      }

      const formatStr = 'yyyy/MM/dd HH:mm'
      const formattedStartTime = format(new Date(startTime.value), formatStr)
      const formattedEndTime = format(new Date(endTime.value), formatStr)

      return `${formattedStartTime} ~ ${formattedEndTime}`
    })

    const isArbitraryRangeFetch = computed(() => {
      return props.allowArbitraryRange && arbitraryRangeEnabled.value && endDate.value
    })

    const queryObj = computed(() => {
      if (isArbitraryRangeFetch.value) return { date: date.value, endDate: endDate.value }

      return { date: date.value, interval: interval.value }
    })

    const rangeObj = computed(() => {
      return { startTime: startTime.value, endTime: endTime.value }
    })

    const getRange = async () => {
      if (!date.value) return

      isLoadingRange.value = true

      const res = await IntervalApi.getRange({
        date: date.value,
        interval: interval.value,
        ...(isArbitraryRangeFetch.value ? { endDate: endDate.value } : {}),
      })

      if (res?.status === 200) {
        startTime.value = res.data.startTime
        endTime.value = res.data.endTime

        addQuery(queryObj.value, true)
        emit('range-updated', rangeObj.value)
        emit('picked', queryObj.value)
      }

      isLoadingRange.value = false
    }

    const onChangeInterval = async event => {
      interval.value = event

      await getRange()

      emit('interval-updated', interval.value)
    }

    const onPickDate = async event => {
      date.value = event

      await getRange()

      emit('date-updated', date.value)

      isPickingDate.value = false
    }

    const onPickRangeDates = async event => {
      if (event[0] > event[1]) {
        isPickingDate.value = false
        rangeDates.value = [date.value, date.value]

        return
      }

      date.value = event[0]
      endDate.value = event[1]

      await getRange()
      emit('date-updated', date.value)
      emit('end-date-updated', endDate.value)

      isPickingDate.value = false
    }

    watch(() => arbitraryRangeEnabled.value, (newBool, prevBool) => {
      // NOTE: falseからtrueになったとき
      if (!prevBool && newBool) {
        endDate.value = date.value
        rangeDates.value = [date.value, date.value]

        emit('end-date-updated', endDate.value)

        removeQuery('interval', true)

        getRange()
      }

      // NOTE: trueからfalseになったとき
      if (prevBool && !newBool) {
        date.value = rangeDates.value[0]
        endDate.value = ''

        emit('end-date-updated', endDate.value)

        removeQuery('endDate', true)

        getRange()
      }
    })

    watch(() => interval.value, (newVal, _) => {
      // NOTE: intervalの値がおかしいときは0にする
      if (!(+newVal >= 0 && +newVal <= (props.year ? 3 : 2))) {
        interval.value = 0
      }
    }, { immediate: true })

    getRange()

    return {
      // data
      date,
      interval,
      isPickingDate,
      isLoadingRange,
      intervals,
      startTime,
      endTime,
      arbitraryRangeEnabled,
      endDate,
      rangeDates,

      // computed
      displayRange,
      isArbitraryRangeFetch,

      // methods
      onChangeInterval,
      onPickDate,
      onPickRangeDates,
      addMonths,

      icons: {
        mdiCalendar,
        mdiLockOpenVariant,
        mdiLock,
      },
    }
  },
}
</script>
