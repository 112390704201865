import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getRange({ date, interval, endDate = null }) {
    return axiosIns.get(
      `/waiter/intervals/${date}/range/${interval}`,
      {
        params: snakecaseKeys({
          endDate,
        }),
      },
    )
  },
}
